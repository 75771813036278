import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import './TermAndConditions.css';
import CIBILLOGO from "../img/poweredbyCIBILlgo.jpg"
import Button from '@mui/material/Button';


const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);


const consentPoints = [
    {
      id: 1,
      text: "The Agent is my lawfully appointed agent and he / it has agreed to be my agent for the purposes, including, without limitation, to receive the Product from TU CIBIL on my behalf and use it in the manner consistent with the end-use policy of my Agent (“Agent’s End Use Policy”) or the understanding between me and my Agent (“Terms of Understanding”), as the case may be, and the Agent has granted its consent for being appointed for the aforesaid purpose."
    },
    {
      id: 2,
      text: "I grant my unconditional consent to the Agent to receive the Product from TU CIBIL on my behalf and use it in the manner consistent with the Agent’s End Use Policy or the Terms of Understanding, as the case may be, and the Agent has granted its consent for being appointed for the aforesaid purpose. I hereby represent and acknowledge that: (a) I have carefully read the terms and conditions of the Agent’s End Use Policy and understood the same; or (b) the Terms of Understanding in relation to the use of the Product has been agreed between me and my Agent."
    },
    {
      id: 3,
      text: "I hereby expressly grant unconditional consent to, and direct, TU CIBIL to deliver the Product to the Agent on my behalf."
    },
    {
      id: 4,
      text: "I shall not hold TU CIBIL responsible or liable for any loss, claim, liability, or damage of any kind resulting from, arising out of, or in any way related to: (a) delivery of the Product to the Agent; (b) any use, modification or disclosure by the Agent of the contents, in whole or in part, of the Product, whether authorized or not; (c) any breach of confidentiality or privacy in relation to delivery of the Product to the Agent; (d) for any use made by the Agent which is contrary to the Agent’s End Use Policy or Terms of Understanding or otherwise."
    },
    {
      id: 5,
      text: "I acknowledge and accept that: (a) TU CIBIL has not made any promises or representations to me in order to induce me to provide the Product Request or seek any consent or authorization in this regard; and (b) the implementation of the Agent’s End Use Policy or Terms of Understanding is solely the responsibility of the Agent."
    },
    {
      id: 6,
      text: "I agree that I may be required to record my consent / provide instructions electronically and in all such cases I understand that by clicking on the I Accept button below, I am providing written instructions to [Agent] authorizing [Agent] to obtain my Consumer Credit Information from my personal credit profile from TransUnion CIBIL LIMITED, [formerly known as Credit Information Bureau (India) Limited]]. I further authorize [Agent] to obtain such information solely to confirm my identity and deliver Product to me.  Further in all such cases “By checking this box and clicking on the ‘Authorize button,  I agree to the terms and conditions, acknowledge receipt of TU CIBIL privacy policy and agree to its terms, and confirm my  authorization for [COMPANY] to obtain my Consumer Credit Information ."
    },
    {
      id: 7,
      text:"I understand that in order to deliver the Product to me, I hereby authorize [Agent], to obtain my Consumer Credit Information from TU CIBIL."
    },
    {
      id:8,
      text:"By submitting this registration form, I understand that I am providing express written instructions for [Agent] to request and receive information about me from third parties, including but not limited to a copy of my consumer credit report and score from consumer reporting agencies, at any time for so long as I have an active [Agent] account. I further authorize [Agent] to retain a copy of my information for use in accordance with [Agent’s] Terms of Use and Privacy Policy."
    },
    {
      id:9,
      text: "I UNDERSTAND THAT THE PRODUCT IS PROVIDED ON AN “AS-IS”, “AS AVAILABLE” BASIS AND TU CIBIL EXPRESSLY DISCLAIMS ALL WARRANTIES, INCLUDING THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT."
    },
    {
      id:10,
      text: "I shall not sue or otherwise make or present any demand or claim, and I irrevocably, unconditionally and entirely release, waive and forever discharge TU CIBIL, its officers, directors, employees, agents, licensees, affiliates, successors and assigns, jointly and individually (hereinafter “Releasee”), from any and all manner of liabilities, claims, demands, losses, claims, suits, costs and expenses (including court costs and reasonable attorney fees) (“Losses”), whatsoever, in law or equity, whether known or unknown, which I ever had, now have, or in the future may have against the Releasee with respect to the submission of the Product Request and / or my decision to provide TU CIBIL with the authority to deliver the Product to the Agent. I agree to defend, indemnify, and hold harmless the Releasee from and against any and all Losses resulting from claims made against TU CIBIL by third parties arising from and in connection with this letter."
    },
    {
      id:11,
      text: "I agree that the terms of this confirmation letter shall be governed by the laws of India and shall be subject to the exclusive jurisdiction of the courts located in Mumbai in regard to any dispute arising hereof. TU CIBIL is entitled to assign its rights hereunder to any third person without taking my prior written consent.  "
    }
    // Add more items as necessary
  ];


function TermandCondition() {
    return (
        <>
           <div className="terms-and-conditions">
         <h6 style={{textAlign:'center'}}>USER CONSENT FOR DELIVERY OF PRODUCT</h6>
      <div className="powered-by">
        <p>Powered by</p>
        <img src={CIBILLOGO} alt="CIBIL Logo" />
        <p>Part of TransUnion</p>
      </div>

      <div>
        <div>
        <address>
          To,<br />
          TransUnion CIBIL Limited<br />
          One Indiabulls building, 19th Floor, Tower 2A-2B,<br />
          Jupiter Mill compound, Senapati Bapat Marg, Lower Parel,<br />
          Mumbai 400013
        </address>
        <section>
        <h6>Sub: Submission of Request and Grant of Consent </h6>
        <p>Dear Sir,</p>
        <p>In connection with submission of the application for receiving my credit information offered by TransUnion CIBIL Limited (TU CIBIL) (“Product”) through submission of request form (“Product Request”) through ArkaFincap Private Limited (referred to as the “Agent”) and delivery of the Product to the Agent, I hereby acknowledge and agree to the following:</p>
        <ul>
          {consentPoints.map(point => (
            <li key={point.id}>{point.text}</li>
          ))}
        </ul>
      </section>
      <p>Yours faithfully,</p>

        </div>
      </div>
      
    </div>
        </>
    );
}

export default TermandCondition;
