import { useState } from 'react';

function Loader() {
    const [refreshing, setRefreshing] = useState(1);

    return (
        <>
            <div style={{ width: '98%', height: '98%', position: 'absolute', top: 1, display: 'flex', textAlign: 'center', justifyContent: 'center', backgroundColor: 'rgba(0,0,0,0.1)' }}>
                <div style={{ alignSelf: 'center' }}>
                    <div class="text-center">
                        <div class="spinner-border" role="status">
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Loader;
