import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import Cookies from 'js-cookies';
import axios from 'axios';
import { decode,  } from "base-64";


function Protected(props) {
    const navigate = useNavigate();
    const { Component } = props;
    const [isLoading,setIsLoading] = useState(true);
    let { value } = useParams();
    let c = decode(value);
    let data = JSON.parse(c);
    // let { application_Id, appr_tag ,applicantName, approvedAmount, apprEmail } = useParams();


    const getLoanData = async () => {
        // console.log(data)
        const config = { headers: { authorization: `Bearer ${'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJwcm9kdWN0aW9uIiwidXNlclR5cGUiOiJ3aG9sZXNhbGUiLCJpYXQiOjE2NzU2NjYyMzR9.GrADzNxuhqCypJdM1xw7Db2k4AhBZ_PB_zDCmeAozkw'}` } }; 
        // const result = await axios.get(`http://localhost:8080/users/get-cibil-detail/${data.application_Id}`,config)
        const result = await axios.get(`https://services.arkafincap.com/users/get-cibil-detail/${data.application_Id}`,config)
        if (result.data.data == "available") {
            // console.log(data.application_Id)
            navigate('/arka/alert1');
        }
    }


    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);        
          }, 1000);
          getLoanData();

        // let login = Cookies.getItem("token")
        //  Cookies.removeItem("token");
        // if (login === "APPROVED") {
        //     console.log(login);
        //     navigate('/arka/alert1');
        // }
        // else {
        //     getLoanData();
        // }
    }, [])


    return (
        <>
            <div>
                { isLoading ? <div><h1>Loading.......</h1></div> :
                <Component /> }
            </div>
        </>
    );
}

export default Protected;
