import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

function RedirectModal({ show, handleClose, meetCode }) {
  const [isChecked, setIsChecked] = useState(false);
  

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleSubmit = () => {
	console.log(meetCode)
    if (isChecked) {
        window.location.href = `https://meet.google.com/${meetCode}`;;
    } else {
      alert('Please check the box before proceeding');
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Alert</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Attention! Please start the meeting recording now.</p>
        <Form.Check 
          type="checkbox" 
          label="I agree " 
          checked={isChecked} 
          onChange={handleCheckboxChange} 
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit}>
          Submit
        </Button>
        
      </Modal.Footer>
    </Modal>
  );
}

export default RedirectModal;
