import logo from "../img/arkalogo.png"

function Nav() {
    return (
        <div>
            <nav className="navbar bg-body-tertiary Regular shadow p-0">
                <div className="container">
                    <a className="navbar-brand">
                       <img src={logo} className="img-thumbnail hover-zoom" />
                    </a>
                </div>
            </nav>
        </div>
    );
}
export default Nav;
