import React, { useState, useEffect } from 'react';
import RedirectModal from './RedirectModal';

function Meet() {
  const [showModal, setShowModal] = useState(true);
  const [meetCode, setMeetCode] = useState(null);

  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    // Check for meetcode in the URL parameters
    const params = new URLSearchParams(window.location.search);
    const code = params.get('google_meet_code');
    setMeetCode(code);

    // Show modal only if meetcode is present
    if (code) {
      setShowModal(true);
    } else {
      alert('Meet code is missing in the request');
      // Optionally redirect to an error page or display a message
    }
  }, []);

  return (
    <div className="Meet">
      
      {meetCode ? (
        <RedirectModal show={showModal} handleClose={handleCloseModal} meetCode={meetCode} />
      ) : (
        <p>Error: Google Meet code is missing.</p>
      )}
    </div>
  );
}

export default Meet;
